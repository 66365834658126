<template>
  <div>
    <div class="ant-row ant-form-inline">
      <div class="ant-col ant-col-offset-23 ant-col-1">
        <a-button type="primary" @click="showModal('new')"> 新增套餐 </a-button>
      </div>
    </div>
    <a-table
      :columns="columns"
      :dataSource="data"
      :pagination="false"
      :loading="loading"
    >
      <template slot="link_addr" slot-scope="text">
        <a :href="text">{{ text }}</a>
      </template>
      <template slot="visible" slot-scope="text, record">
        <a-switch :default-checked="text" @change="onChange(record)" />
      </template>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="handleById(record)">编辑</a>
      </span>
    </a-table>
    <Pagination
      :current="current"
      :total="total"
      @handleChange="handleChange"
      v-show="total > 20"
    />
    <a-modal
      :title="title"
      v-model="visible"
      @ok="handleOk"
      @cancel="handleCancel"
      okText="确认"
      cancelText="取消"
    >
      <a-form
        :form="form2"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-form-item label="套餐名称">
          <a-input
            v-decorator="[
              'name',
              { rules: [{ required: true, message: '20字以内' }] },
            ]"
          />
        </a-form-item>
        <a-form-item label="服务周期">
            <a-input-number
              style="width: 40%"
              class="mr-20"
              v-decorator="[
                'period',
                { rules: [{ type: 'number', required: true, message: '请输入正整数', min: 1, max: 99999}] },
              ]"
            />
            <a-radio-group 
                v-decorator="['period_type']">
              <a-radio value="month">月</a-radio>
              <a-radio value="day">天</a-radio>
            </a-radio-group>
        </a-form-item>
        <a-form-item label="套餐价格(元)">
              <a-input-number
                style="width: 100%"
                v-decorator="[
                  'price',
                  { rules: [{ required: true, message: '请输入整数' }] },
                ]"
              />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { VipSetsModel } from "../../models/vipSets";
import Pagination from "../../components/Pagination";
import { mapMutations } from "vuex";
import formatTime from "../../utils/formatTime";
const vipSetsModel = new VipSetsModel();
const columns = [
  {
    title: "套餐名称",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "服务周期",
    dataIndex: "period",
    key: "period",
  },
  {
    title: "套餐价格（元）",
    dataIndex: "price",
    key: "price",
  },
  {
    title: "状态",
    dataIndex: "on_sale",
    key: "on_sale",
    scopedSlots: { customRender: "visible" },
  },
  {
    title: "创建时间",
    dataIndex: "created_at",
    key: "created_at",
  },
  {
    title: "操作",
    dataIndex: "",
    key: "action",
    scopedSlots: {
      customRender: "action",
    },
  },
];
export default {
  data() {
    return {
      form1: this.$form.createForm(this, {
        name: "horizontal_login",
      }),
      data: [],
      columns,
      visible: false,
      formLayout: "horizontal",
      form2: this.$form.createForm(this, { name: "coordinated" }),
      flag: "",
      editId: 0,
      loading1: false,
      loading2: false,
      loading3: false,
      total: 0,
      current: 1,
      period: 0,
      price: 0
    };
  },

  created() {
    this.queryVipSetsList();
  },

  methods: {
    onChange(record) {
      vipSetsModel.update({ on_sale: !record.on_sale }, record.id).then((res) => {
        if (res.code == 0) {
          this.$notification.open({
            message: "提示",
            description: res.message,
            duration: 2,
          });
          this.queryVipSetsList();
        }
      });
    },

    handleById(record, flag) {
      this.flag = flag;
      this.visible = true;
      this.title = "编辑";
      this.editId = record.id;
      this.$nextTick(() => {
        this.form2.setFieldsValue({
          name: record.name,
          period: record.period,
          period_type: record.period_type,
          price: record.price
        });
      });
    },

    showModal(flag) {
      this.flag = flag;
      this.visible = true;
      this.title = "新增";
    },

    handleOk(e) {
      e.preventDefault();
      this.form2.validateFields((err, values) => {
        if (!err) {
          if (this.flag == "new") {
            vipSetsModel.create(values).then((res) => {
              if (res.code == 0) {
                this.visible = false;
                this.$notification.open({
                  message: "提示",
                  description: res.message,
                  duration: 2,
                });
                this.queryVipSetsList();
              }
            });
          } else {
            vipSetsModel.update(values, this.editId).then((res) => {
              if (res.code == 0) {
                this.visible = false;
                this.$notification.open({
                  message: "提示",
                  description: res.message,
                  duration: 2,
                });
                this.queryVipSetsList();
              }
            });
          }
          this.form2.resetFields();
        }
      });
    },

    queryVipSetsList(key, page) {
      this.loading = true;
      vipSetsModel.index(key, page).then((res) => {
        if (res.code == 0) {
          this.current = page;
          res.result.map((item, index) => {
            item.key = index;
            item.created_at = formatTime(item.created_at);
            if (item.period_type == "month"){
              item.period = item.period + " 个月";
            } else {
              item.period = item.period + " 天";
            }
            return item;
          });
          this.data = res.result;
          this.loading = false;
          this.total = res.total;
        }
      });
    },

    handleCancel() {
      this.form2.resetFields();
    },

    ...mapMutations({
      parentName: "PARENT_NAME",
    }),
  },

  components: {
    Pagination,
  },
};
</script>

<style lang="scss" scoped>
.ant-upload-picture-card-wrapper {
  width: 110px;
  margin-right: 10px;
}
.ant-upload.ant-upload-select-picture-card {
  margin-bottom: -14px;
  img {
    width: 100%;
  }
}
.avatar-uploader > .ant-upload {
  width: 110px;
  height: 110px;
  float: left;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.upload-word span {
  width: 110px;
  display: inline-block;
  text-align: center;
  margin-right: 10px;
  font-size: 13px;
  color: #333;
}
</style>