import {HTTP} from '../utils/axios'

class VipSetsModel extends HTTP {
  /*首页渲染*/
  index(key = '',page = 1) {
      let data = {}
      data.key = key
      data.page = page
      return this.request({
          url: 'board/vip_sets',
          method: "GET",
          data: data
      })
  }

  /*增加*/
  create(value) {
    return this.request({
        url: "board/vip_sets",
        method: "POST",
        data: value,
    })
  }

  /*修改*/
  update(value, id) {
    return this.request({
        url: "board/vip_sets/" + id,
        method: "PATCH",
        data: value,
    })
  }
}

export {VipSetsModel}